import { Component, OnInit, DoBootstrap, ElementRef } from '@angular/core';

import { LazyLoaderService } from './lazy-loader.service';
import { EnvService } from './shared/env.service';

@Component({
  selector: 'polylearn-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'landing';

  constructor(
    private lazyLoader: LazyLoaderService,
    private elRef: ElementRef,
    public envService: EnvService
  ) {}

  ngOnInit() {
    this.lazyLoader.load(this.elRef.nativeElement);
  }
}
