export const environment = {
  production: true,

  // API_URL: 'https://api.preprod.polylearn.co',
  EXAM: 'lerobert',
  API_URL: 'https://api.polylearn.co',
  /*
  EXAM: 'linguaskill',
  */
  ENVIRONMENT: {
    graphqlUrl:'https://graphql.polylearn.co/v1/graphql',
    nodeUrl:'https://node.api.polylearn.co/api/'
  },
  firebase: {
    apiKey: "AIzaSyC4pVCL5doRv5U_1o05Fmr1Kywowqx2NOA",
    authDomain: "toeic-new-design.firebaseapp.com",
    databaseURL: "https://toeic-new-design.firebaseio.com",
    projectId: "toeic-new-design",
    storageBucket: "toeic-new-design.appspot.com",
    messagingSenderId: "760238145503",
    appId: "1:760238145503:web:ca9138c1b7302e12424e6a"
  },
  data: {
    brandName: "Le Robert",
    brandNameAsCertification: true,
    brandLogoLoading: "/assets/lerobert/img/loading.svg",
    brandLogoLoadingHeight: 100,
    brandLogo: "/assets/lerobert/img/lerobert_logo_wt.svg",
    brandLogoSmall: "/assets/lerobert/img/le_robert_small.svg",
    langForExercises: "fr",
    goBackOnExercises: false,
    autoHideconsigne: false,
    autoplay: {
      initialValue: false,
      show: false,
    }
  },
  index: {
    "announcement": {
      html: "<b>La référence des plateformes de formation en langue française</b>",
      link: null
    },
  },
  navbar: [
    {
      text: 'Connexion',
      link: 'login',
      show: true,
      action: true,
    },
    {
      text: 'Nous contacter',
      link: 'contact',
      show: true,
      action: true
    },
  ],
};
