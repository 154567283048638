import { BrowserModule } from '@angular/platform-browser';
import { NgModule, DoBootstrap, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';

import { UpgradeModule } from '@angular/upgrade/static';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { ScullyLibModule } from '@scullyio/ng-lib';
import { RouterModule } from '@angular/router';

import { SharedModule } from './shared/shared.module';
import { HttpClientModule } from '@angular/common/http';
import { AngularJsComponent } from './angular-js/angular-js.component';
import { TimerComponent } from './training/shared/timer/timer.component';
import { DisplayTimePipe } from './training/shared/timer/display-time.pipe';
import { DialogAlertComponent } from './training/shared/dialogs/dialog-alert/dialog-alert.component';
import { MatDialogModule } from '@angular/material/dialog';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { UsersListComponent } from './training/shared/users-list/users-list.component';
import { MatTableModule } from '@angular/material/table';
import { MatMenuModule } from '@angular/material/menu';
import { ParseDurationPipe } from './training/shared/parse-duration.pipe';
import { ScorePipe } from './training/shared/score.pipe';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MomentModule } from 'ngx-moment';
import { ChangeWorkspaceComponent } from './admin/change-workspace/change-workspace.component';

import { MatTabsModule } from '@angular/material/tabs';

import { DefaultOptions, from, HttpLink } from '@apollo/client/core';
import { APOLLO_OPTIONS } from 'apollo-angular';

import { RetryLink } from '@apollo/client/link/retry';
import { onError } from '@apollo/client/link/error';
import { setContext } from '@apollo/client/link/context';
import { environment } from '../environments/environment';
import { UtilsService } from './shared/utils.service';

import { aCache } from './apolloCache';

import { AngularFireModule } from '@angular/fire';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatSnackBarModule } from '@angular/material/snack-bar';

const uri = environment.ENVIRONMENT.graphqlUrl;

export function createApollo(utilsService: UtilsService) {
  const retryLink = new RetryLink();

  const errorLink = onError(
    ({ graphQLErrors, networkError, operation, response }) => {
      console.error(`[GraphQL error]: Operation`, JSON.stringify(operation));

      console.error(`[GraphQL error]: Response`, JSON.stringify(response));

      if (graphQLErrors) {
        graphQLErrors.forEach(({ message, locations, path }) => {
          console.error(
            `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
          );
        });
      }
      if (networkError) {
        console.error(`[Network error]: ${networkError}`);
      }
    }
  );

  // add auth header token
  const auth = setContext(async (_, context) => {
    const token = utilsService.getMyToken();
    return {
      fetchPolicy: 'no-cache',
      headers: {
        ...context.headers,
        token: `${token}`,
        exam: environment.EXAM
      }
    };
  });

  return {
    cache: aCache,
    link: from([
      errorLink,
      auth,
      retryLink,
      new HttpLink({
        uri: environment.ENVIRONMENT.graphqlUrl
      })
    ])
  };
}
import { RecordAudioComponent } from './training/shared/record-audio/record-audio.component';
import { ManualCorrectionComponent } from './teacher/manual-correction/manual-correction.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { SupportViewComponent } from './trainings/support/support-view/support-view.component';
import { LerobertHomeComponent } from './home/lerobert/home/home.component';
import { LeRobertHome2Component } from './home/lerobert/home2/home2.component';
import { LeRobertFooterComponent } from './home/lerobert/components/footer/footer.component';
import { MatSelectModule } from '@angular/material/select';
import { SwitchLevelDialog } from './shared/components/switch-level-dialog.component';
import { InstitutionsListComponent } from './training/shared/institutions-list/institutions-list.component';

@NgModule({
  declarations: [
    AppComponent,
    AngularJsComponent,
    TimerComponent,
    DisplayTimePipe,
    DialogAlertComponent,
    UsersListComponent,
    LerobertHomeComponent,
    LeRobertHome2Component,
    LeRobertFooterComponent,
    ParseDurationPipe,
    ScorePipe,
    RecordAudioComponent,
    ManualCorrectionComponent,
    SupportViewComponent,
    SwitchLevelDialog,
    InstitutionsListComponent
  ],
  imports: [
    BrowserModule,
    UpgradeModule,
    BrowserAnimationsModule,
    MatPaginatorModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireStorageModule,
    RouterModule,
    HttpClientModule,
    SharedModule,
    AppRoutingModule,
    MatTabsModule,
    FormsModule,
    MatSelectModule,
    /*
    LocationUpgradeModule.config(
      {
        useHash: true,
        hashPrefix: '!',
      }
      ),
    */

    MatDialogModule,
    MatTableModule,
    MatMenuModule,
    MatSortModule,
    MomentModule,
    FlexLayoutModule,
    MatSnackBarModule,
    ScullyLibModule,
    ReactiveFormsModule,
    SharedModule,
    MatInputModule,
    MatFormFieldModule
  ],
  providers: [
    HttpClientModule,
    {
      provide: APOLLO_OPTIONS,
      useFactory: createApollo,
      deps: [UtilsService]
    },
    {
      provide: 'api',
      useFactory: ($injector: any) => $injector.get('api'),
      deps: ['$injector']
    }
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  entryComponents: [DialogAlertComponent]
})
export class AppModule implements DoBootstrap {
  constructor(private upgrade: UpgradeModule) {}

  ngDoBootstrap() {
    // this.upgrade.bootstrap(document.body, ['PlatformExam'], { strictDi: true });
  }
}
